Sage.common = {
  init: function() {
    // JavaScript to be fired on all pages
    $('#menu-primary-navigation li').click(function() {
      var menuItem = $(this);
      var menuContainer = $('.nav-primary-locations');

      if ((menuItem.is('.menu-menus, .menu-locations')) && $('.nav-primary-locations:hidden')) {
        var backgroundImage, linkText, linkLocation = '';

        var $locations = menuContainer.find('.nav-primary-location');

        $locations.each(function() {
          var $location = $(this);
          var $locationButton = $location.find('.nav-primary-location-button');
          var locationHref = $locationButton.prop('href');
          if (locationHref.indexOf("#") > -1) {
            // Strip the hash off the URL if it has one so we're in a known state
            locationHref = locationHref.substring(0, locationHref.indexOf('#'));
          }

          if (menuItem.hasClass('menu-menus')) {
            backgroundImage = $location.data('menus-img');
            linkText = 'View Menu';
            locationHref += '#menu';
          } else if (menuItem.hasClass('menu-locations')) {
            backgroundImage = $location.data('locations-img');
            linkText = 'More Info';
          }
          if (locationHref.indexOf("disabled") >= 0) {
            linkText = 'Coming Soon';
          }
          // Set everything the way we want
          $location.css('backgroundImage', 'url(' + backgroundImage + ')');
          $locationButton.text(linkText);
          $locationButton.prop('href', locationHref);
        });
          menuContainer.slideToggle();
          menuContainer.toggleClass('open');
      }
      else {
        menuContainer.delay(250).slideUp();
      }
    });
    $('#menu-primary-navigation li').click(function() {
      var menuItem = $(this);
      var orderContainer = $('.nav-primary-order-online');

      if ((menuItem.is('.menu-order-online')) && $('.nav-primary-order-online:hidden')) {
        orderContainer.slideToggle();
        orderContainer.toggleClass('open');
      }
      else {
        orderContainer.delay(250).slideUp();
      }
    });
    $('.main-toggle').click( function() {
      $(this).next('.nav-primary').slideToggle();
      $('body').toggleClass('modal-open');
    });
    $('.order-toggle').click( function() {
      $(this).next('.drop-container').slideToggle();
      $('body').toggleClass('modal-open');
    });
    $('.locations-toggle').click( function() {
      $(this).next('.drop-container').slideToggle();
      $('body').toggleClass('modal-open');
    });
    $('.nav-primary-locations').click(function() {
      $(this).delay(250).slideUp();
    });

    $('.header-gallery').flickity({
      cellAlign: 'center',
      contain: true,
      pageDots: false
    });
     // Smooth Scroll to Anchors //
        $('.anchor').on('click',function (e) {
          e.preventDefault();

          var target = this.hash;
          var $target = $(target);

          $('html, body').stop().animate({
              'scrollTop': $target.offset().top
          }, 900, 'swing', function () {
              window.location.hash = target;
          });
        });

/*!
 * cellsByRows layout mode for Isotope
 * v1.1.4
 * https://isotope.metafizzy.co/layout-modes/cellsbyrow.html
 */

/*jshint browser: true, devel: false, strict: true, undef: true, unused: true */

( function( window, factory ) {
  // universal module definition
  /* jshint strict: false */ /*globals define, module, require */
  if ( typeof define === 'function' && define.amd ) {
    // AMD
    define( [
        'isotope-layout/js/layout-mode'
      ],
      factory );
  } else if ( typeof exports === 'object' ) {
    // CommonJS
    module.exports = factory(
      require('isotope-layout/js/layout-mode')
    );
  } else {
    // browser global
    factory(
      window.Isotope.LayoutMode
    );
  }

}( window, function factory( LayoutMode ) {
  'use strict';

  var CellsByRow = LayoutMode.create( 'cellsByRow' );
  var proto = CellsByRow.prototype;

  proto._resetLayout = function() {
    // reset properties
    this.itemIndex = 0;
    // measurements
    this.getColumnWidth();
    this.getRowHeight();
    // set cols
    this.cols = Math.floor( this.isotope.size.innerWidth / this.columnWidth );
    this.cols = Math.max( this.cols, 1 );
  };

  proto._getItemLayoutPosition = function( item ) {
    item.getSize();
    var col = this.itemIndex % this.cols;
    var row = Math.floor( this.itemIndex / this.cols );
    // center item within cell
    var x = ( col + 0.5 ) * this.columnWidth - item.size.outerWidth / 2;
    var y = ( row + 0.5 ) * this.rowHeight - item.size.outerHeight / 2;
    this.itemIndex++;
    return { x: x, y: y };
  };

  proto._getContainerSize = function() {
    return {
      height: Math.ceil( this.itemIndex / this.cols ) * this.rowHeight
    };
  };

  return CellsByRow;

}));


  $( document ).ready(function() {
    var $grid = $('.products').isotope({
      layoutMode: 'fitRows',
      fitRows: {
        gutter: 30
      },
      itemSelector: '.product'
  });

  // store filter for each group
var filters = [];

    // bind filter button click
    $('.filters-button-group').on( 'click', 'button', function() {
      /*var filterValue = jQuery( this ).attr('data-filter');
      console.log(filterValue);
      $grid.isotope({ filter: filterValue });*/
      var $target = $( this );
      $target.toggleClass('is-checked');
      var isChecked = $target.hasClass('is-checked');
      var filter = $target.attr('data-filter');
      if ( isChecked ) {
        addFilter( filter );
      } else {
        removeFilter( filter );
      }

      if(isChecked = $target.hasClass('allbutton')){
        filters.length = 0;
        filters.push('*');
        $('.filters-button-group button').removeClass('is-checked');
        $target.addClass('is-checked');
      } else {
        $('.filters-button-group .allbutton').removeClass('is-checked');
        for( var i = 0; i < filters.length; i++){ 
            if ( filters[i] === '*') { 
              filters.splice(i, 1); 
            }
        }
      }

      // filter isotope
      // group filters together, inclusive
      $grid.isotope({ filter: filters.join(',').toString() });
      console.log(filters);

      
    }); 

function addFilter( filter ) {
  if ( filters.indexOf( filter ) == -1 ) {
    filters.push( filter );
  }
}

function removeFilter( filter ) {
  var index = filters.indexOf( filter);
  if ( index != -1 ) {
    filters.splice( index, 1 );
  }
}
});

  },
  finalize: function() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
